import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateMembershipArgs } from '../../generated/types';

export const CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($data: MembershipInputData!) {
    createMembership(data: $data) {
      clientSecret
      subscriptionId
      invoice
    }
  }
`;

export const useCreateMembership = (
  options?: MutationHookOptions<Pick<Mutation, 'createMembership'>, MutationCreateMembershipArgs>
) =>
  useMutation<Pick<Mutation, 'createMembership'>, MutationCreateMembershipArgs>(CREATE_MEMBERSHIP, {
    ...options,
  });
