import { SubmitHandler } from 'react-hook-form';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';

import creditCardAnimation from '../../assets/lotties/credit-card.json';

import { useGetProducts } from '../../graph/queries/getProducts';
import { useCreateMembership } from '../../graph/mutations/createMembership';
import { useCancelMembership } from '../../graph/mutations/cancelMembership';

import { Colors } from '../../styles/colors';
import { Urls } from '../urls';
import { Plan } from '../../generated/types';

import { Form } from '../../components/atoms/Form/Form';
import { CheckIcon, ChevronRightIcon } from '../../components/atoms/Icons';
import { Copy, H2, H4 } from '../../components/atoms/Typography';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import { useUserContext } from '../../contexts/UserContext';
import { GET_ME } from '../../graph/queries/me';
import { Paper } from '../../components/atoms/Paper';
import { Link, PrimaryButton, SecondaryButton } from '../../components/atoms/Button';
import { Pricing } from '../../components/molecules/Pricing';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { Sections, Section } from '../../components/atoms/Section';
import { BulletList } from '../../components/atoms/BulletList';
import { Faq } from '../../components/atoms/Faq';
import { GenericErrorAlert } from '../../components/atoms/InfoAlert';
import { converToPrice } from '../../utils/price';
import { Placeholder } from '../../components/atoms/Placeholder';
import { Lottie } from '../../components/atoms/Lottie';
import { Size } from '../../components/atoms/Placeholder/Placeholder';

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const Checkout = () => {
  const user = useUserContext();
  const [createMembership] = useCreateMembership();
  const [cancelMembership] = useCancelMembership();
  const { data: { products = [] } = {} } = useGetProducts({
    variables: { where: { isDeleted: false } },
  });
  const [selectedPlan, setSelectedPlan] = useState<Plan>(user?.membershipPlan || Plan.Free);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [subscriptionId, setSubscriptionId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [globalError, setGlobalError] = useState<string>('');
  const [invoice, setInvoice] = useState<any>({});
  const paymentRef = useRef<any>(null);
  const hadTrial = user?.hadTrial || false;

  useEffect(() => {
    if (user?.membershipPlan) {
      setSelectedPlan(user?.membershipPlan);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlanSelect = async (plan: Plan) => {
    setSelectedPlan(plan);
    setClientSecret('');
    setSubscriptionId('');
    setAction('');

    if (user?.membershipPlan === Plan.Free && plan === Plan.Free) {
      return;
    }

    if (user?.membershipPlan !== Plan.Free && (plan === Plan.Monthly || plan === Plan.Quarterly)) {
      return;
    }

    setGlobalError('');
    setIsLoading(true);
    paymentRef.current?.scrollIntoView({ behavior: 'smooth' });

    switch (plan) {
      case Plan.Free: {
        setAction('DOWNGRADE_TO_FREE');
        break;
      }

      case Plan.Trial: {
        setAction('UPGRADE_TO_TRIAL');
        const trialProductsMap = ['price_1Oh4wkGcHNail995Stmxdytb', 'price_1OjePHGcHNail995srDaaVLO'];

        const product = products.find((product) => trialProductsMap.includes(product.priceId));
        if (!product || !product.priceId) {
          setGlobalError('Something went wrong. Please try again later.');
          return;
        }

        try {
          const response = await createMembership({
            variables: {
              data: {
                subscriptionPriceId: product.priceId,
              },
            },
          });

          if (response.data?.createMembership?.clientSecret) {
            setClientSecret(response.data?.createMembership?.clientSecret);
          }

          if (response.data?.createMembership?.subscriptionId) {
            setSubscriptionId(response.data?.createMembership?.subscriptionId);
          }

          if (response.data?.createMembership?.invoice) {
            setInvoice(response.data?.createMembership?.invoice);
          }
        } catch (error: unknown) {
          console.error(error);
          setGlobalError(error.message);
        }
        break;
      }

      case Plan.Monthly: {
        setAction('UPGRADE_TO_PREMIUM_MONTHLY');
        const premiumMonthlyProductsMap = ['price_1OrempGcHNail995jVIppmeR', 'price_1OrejIGcHNail995McTvZLda'];

        const product = products.find((product) => premiumMonthlyProductsMap.includes(product.priceId));
        if (!product || !product.priceId) {
          setGlobalError('Something went wrong. Please try again later.');
          return;
        }
        try {
          const response = await createMembership({
            variables: {
              data: {
                subscriptionPriceId: product.priceId,
              },
            },
          });

          if (response.data?.createMembership?.clientSecret) {
            setClientSecret(response.data?.createMembership?.clientSecret);
          }

          if (response.data?.createMembership?.subscriptionId) {
            setSubscriptionId(response.data?.createMembership?.subscriptionId);
          }

          if (response.data?.createMembership?.invoice) {
            setInvoice(response.data?.createMembership?.invoice);
          }
        } catch (error: unknown) {
          console.error(error);
          setGlobalError(error.message);
        }
        break;
      }

      case Plan.Quarterly: {
        if (user?.membershipPlan !== Plan.Free) return;
        setAction('UPGRADE_TO_PREMIUM_QUARTERLY');
        const premiumQuaterlyProductsMap = ['price_1Oreo1GcHNail9954QciaY8t', 'price_1OrekYGcHNail995uQFXls2F'];

        const product = products.find((product) => premiumQuaterlyProductsMap.includes(product.priceId));
        if (!product || !product.priceId) return;
        try {
          const response = await createMembership({
            variables: {
              data: {
                subscriptionPriceId: product.priceId,
              },
            },
          });

          if (response.data?.createMembership?.clientSecret) {
            setClientSecret(response.data?.createMembership?.clientSecret);
          }

          if (response.data?.createMembership?.subscriptionId) {
            setSubscriptionId(response.data?.createMembership?.subscriptionId);
          }

          if (response.data?.createMembership?.invoice) {
            setInvoice(response.data?.createMembership?.invoice);
          }
        } catch (error: unknown) {
          console.error(error);
          setGlobalError(error?.message || error);
        }
        break;
      }
      default:
        break;
    }

    setIsLoading(false);
  };

  const handleCancelSubscription = async () => {
    await cancelMembership({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
    });

    // As long as you’re a happy camper, that’s all that matters! Your Premium subscription has been canceled. Join us again at any time; we’d be glad to have you back.
    toast.success('Your Premium Plan has been cancelled.');
    setAction('');
  };

  return (
    <>
      <TitleWrapper>
        <H4>
          JobsBoard<span>.io</span> is available in these flavors.
        </H4>
        <Copy styleLevel={1} isItalic={true} marginBottom={32}>
          You can upgrade at any time. Choose the one that suits you best.
        </Copy>
      </TitleWrapper>
      <Pricing selectedPlan={selectedPlan} handlePlanSelect={handlePlanSelect} hadTrial={hadTrial} />
      <Spacer y={64} />

      <AnchorWrapper ref={paymentRef} />

      {/* <pre>{JSON.stringify(invoice, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(products, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(selectedPlan, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(clientSecret, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(subscriptionId, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ user }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(action, null, 2)}</pre> */}
      {/* <ErrorBoundary>{confirmPayment}</ErrorBoundary> */}

      {isLoading ? (
        <Placeholder
          lottie={<Lottie animationData={creditCardAnimation} />}
          iconAlt="mail box"
          title="Loading..."
          iconSize={Size.SMALL}
          description={
            <>
              <Copy styleLevel={1}>We are in the process of loading our secure payment form.</Copy>
              <Copy styleLevel={2}>If this takes longer than a 15 seconds, please refresh the page and try again.</Copy>
            </>
          }
        />
      ) : (
        <ErrorBoundary>
          {action === 'DOWNGRADE_TO_FREE' && (
            <div>
              <H2>Are you sure?</H2>
              <Copy marginBottom={16}>
                We’re sorry you decided to leave the Premium family. You can still enjoy all the perks of being Premium
                until your current plan expires. After that, you will no longer have access to all the Premium features.
                That doesn’t feel right, does it?
              </Copy>
              <Copy marginBottom={16}>
                <SecondaryButton inline onClick={handleCancelSubscription}>
                  Yes, downgrade to free
                </SecondaryButton>
              </Copy>
            </div>
          )}
          {action === 'UPGRADE_TO_TRIAL' && clientSecret && subscriptionId && (
            <>
              <Sections>
                <Section>
                  <Paper elevation={1}>
                    <H4>Trial</H4>
                    {/* <Copy marginBottom={16} styleLevel={1} isItalic={true}>
                      7 days trial {converToPrice(190)}
                    </Copy> */}
                    <Copy marginBottom={16} styleLevel={2} isItalic={true}>
                      <strong>Billing terms:</strong> Your {converToPrice(invoice.subtotal)} package lasts 7 days. By
                      clicking "Subscribe" you are providing your electronic signature authorizing JobsBoard.io to
                      charge your card as described. You agree that, unless you cancel your account during your 7 days
                      try-out period, you will be billed {converToPrice(999)} monthly until you cancel your account,
                      which can be done quickly and easily online or by contacting our support team at
                      support@jobsboard.io.
                    </Copy>
                    <Copy marginBottom={16} styleLevel={2} isItalic={true}>
                      Cancel it anytime from your membership settings.
                    </Copy>
                    <Copy marginBottom={16} styleLevel={2} isItalic={true}>
                      By clicking "Confirm and pay" you agree to the{' '}
                      <Link to={`/${Urls.Static}/${Urls.TermsOfUse}`}>Terms and Conditions</Link>.
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Subtotal: {converToPrice(invoice.subtotal)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Tax: {converToPrice(invoice.tax)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Total Excluding Tax: {converToPrice(invoice.totalExcludingTax)}
                    </Copy>
                    <Copy marginBottom={5} styleLevel={1} isItalic={true}>
                      Total Due Today: {converToPrice(invoice.total)}
                    </Copy>
                    {errorMessage && (
                      <Copy marginBottom={16} styleLevel={2} isItalic={true} color={Colors.Error}>
                        {errorMessage}
                      </Copy>
                    )}
                  </Paper>
                </Section>
                <Section>
                  <Paper elevation={1}>
                    <H4>Power up your Job Search</H4>
                    <BulletList $hasBullets={false}>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Payment through a trusted payment service
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        SSL Secure / 256-bit SSL secure checkout
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        14-day money back guarantee
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Cancel anytime online or by email
                      </li>
                    </BulletList>
                    <Spacer y={16} />
                    <Faq title="What’s your cancellation policy?" isOpen={false}>
                      You can cancel your subscription at any time. Once you cancel your subscription, you will not be
                      charged further. However, you will not be refunded for the current billing cycle.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="Money-Back Guarantee!" isOpen={false}>
                      If you are not fully satisfied and still within the 14 day trial period, simply let us know and we
                      will happily process a full refund.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="How to cancel my subscription?" isOpen={false}>
                      <Copy styleLevel={2}>
                        Cancelling your subscription is quick and hassle-free. You have two convenient options:
                      </Copy>
                      <ul>
                        <li>
                          Self-service: You can easily cancel your subscription at any time by accessing your account
                          settings. Simply navigate to the “Settings” and “Membership” section and follow the intuitive
                          cancellation process.
                        </li>
                        <li>
                          Contact support: If you prefer assistance or have any questions regarding the cancellation
                          process, our support team is here to help. Feel free to reach out to them, and they will guide
                          you through the steps or handle the cancellation on your behalf.
                        </li>
                      </ul>
                    </Faq>
                  </Paper>
                </Section>
              </Sections>

              <StripeWrapper clientSecret={clientSecret}>
                <SubscriptionBlock setErrorMessage={setErrorMessage} />
              </StripeWrapper>
            </>
          )}
          {action === 'UPGRADE_TO_PREMIUM_MONTHLY' && clientSecret && subscriptionId && (
            <>
              <Sections>
                <Section>
                  <Paper elevation={1}>
                    <H4>Premium Plan</H4>
                    <Copy marginBottom={16} styleLevel={1} isItalic={true}>
                      Monthly Subscription {converToPrice(999)} / Month
                    </Copy>
                    <Copy marginBottom={16} styleLevel={2} isItalic={true}>
                      Your subscription will renew automatically every month as one payment of {converToPrice(999)}.
                      Cancel it anytime from your subscription settings. By clicking "Confirm and pay" you agree to the{' '}
                      <Link to={`/${Urls.Static}/${Urls.TermsOfUse}`}>Terms and Conditions</Link>.
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Subtotal: {converToPrice(invoice.subtotal)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Tax: {converToPrice(invoice.tax)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Total Excluding Tax: {converToPrice(invoice.totalExcludingTax)}
                    </Copy>
                    <Copy marginBottom={5} styleLevel={1} isItalic={true}>
                      Total Due Today: {converToPrice(999)}
                    </Copy>
                    {errorMessage && (
                      <Copy marginBottom={16} styleLevel={2} isItalic={true} color={Colors.Error}>
                        {errorMessage}
                      </Copy>
                    )}
                  </Paper>
                </Section>
                <Section>
                  <Paper elevation={1}>
                    <H4>Power up your Job Search</H4>
                    <BulletList $hasBullets={false}>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Payment through a trusted payment service
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        SSL Secure / 256-bit SSL secure checkout
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        14-day money back guarantee
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Cancel anytime online or by email
                      </li>
                    </BulletList>
                    <Spacer y={16} />
                    <Faq title="What’s your cancellation policy?" isOpen={false}>
                      You can cancel your subscription at any time. Once you cancel your subscription, you will not be
                      charged further. However, you will not be refunded for the current billing cycle.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="Money-Back Guarantee!" isOpen={false}>
                      If you are not fully satisfied and still within the 14 day trial period, simply let us know and we
                      will happily process a full refund.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="How to cancel my subscription?" isOpen={false}>
                      <Copy styleLevel={2}>
                        Cancelling your subscription is quick and hassle-free. You have two convenient options:
                      </Copy>
                      <ul>
                        <li>
                          Self-service: You can easily cancel your subscription at any time by accessing your account
                          settings. Simply navigate to the “Settings” and “Membership” section and follow the intuitive
                          cancellation process.
                        </li>
                        <li>
                          Contact support: If you prefer assistance or have any questions regarding the cancellation
                          process, our support team is here to help. Feel free to reach out to them, and they will guide
                          you through the steps or handle the cancellation on your behalf.
                        </li>
                      </ul>
                    </Faq>
                  </Paper>
                </Section>
              </Sections>

              <StripeWrapper clientSecret={clientSecret}>
                <SubscriptionBlock setErrorMessage={setErrorMessage} />
              </StripeWrapper>
            </>
          )}
          {action === 'UPGRADE_TO_PREMIUM_QUARTERLY' && clientSecret && subscriptionId && (
            <>
              <Sections>
                <Section>
                  <Paper elevation={1}>
                    <H4>Premium Plan</H4>
                    <Copy marginBottom={16} styleLevel={1} isItalic={true}>
                      Quarterly Subscription {converToPrice(699)} / Month
                    </Copy>
                    <Copy marginBottom={16} styleLevel={2} isItalic={true}>
                      Your subscription will renew automatically every three months as one payment of{' '}
                      {converToPrice(2097)}. Cancel it anytime from your subscription settings. By clicking "Confirm and
                      pay" you agree to the <Link to={`/${Urls.Static}/${Urls.TermsOfUse}`}>Terms and Conditions</Link>.
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Subtotal: {converToPrice(invoice.subtotal)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Tax: {converToPrice(invoice.tax)}
                    </Copy>
                    <Copy styleLevel={3} isItalic={true}>
                      Total Excluding Tax: {converToPrice(invoice.totalExcludingTax)}
                    </Copy>
                    <Copy marginBottom={5} styleLevel={1} isItalic={true}>
                      Total Due Today: {converToPrice(2097)}
                    </Copy>
                    {errorMessage && (
                      <Copy marginBottom={16} styleLevel={2} isItalic={true} color={Colors.Error}>
                        {errorMessage}
                      </Copy>
                    )}
                  </Paper>
                </Section>
                <Section>
                  <Paper elevation={1}>
                    <H4>Power up your Job Search</H4>
                    <BulletList $hasBullets={false}>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Payment through a trusted payment service
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        SSL Secure / 256-bit SSL secure checkout
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        14-day money back guarantee
                      </li>
                      <li>
                        <CheckIcon size={1.3} color={Colors.Contrast} />
                        Cancel anytime online or by email
                      </li>
                    </BulletList>
                    <Spacer y={16} />
                    <Faq title="What’s your cancellation policy?" isOpen={false}>
                      You can cancel your subscription at any time. Once you cancel your subscription, you will not be
                      charged further. However, you will not be refunded for the current billing cycle.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="Money-Back Guarantee!" isOpen={false}>
                      If you are not fully satisfied and still within the 14 day trial period, simply let us know and we
                      will happily process a full refund.
                    </Faq>
                    <Spacer y={16} />
                    <Faq title="How to cancel my subscription?" isOpen={false}>
                      <Copy styleLevel={2}>
                        Cancelling your subscription is quick and hassle-free. You have two convenient options:
                      </Copy>
                      <ul>
                        <li>
                          Self-service: You can easily cancel your subscription at any time by accessing your account
                          settings. Simply navigate to the “Settings” and “Membership” section and follow the intuitive
                          cancellation process.
                        </li>
                        <li>
                          Contact support: If you prefer assistance or have any questions regarding the cancellation
                          process, our support team is here to help. Feel free to reach out to them, and they will guide
                          you through the steps or handle the cancellation on your behalf.
                        </li>
                      </ul>
                    </Faq>{' '}
                  </Paper>
                </Section>
              </Sections>

              <StripeWrapper clientSecret={clientSecret}>
                <SubscriptionBlock setErrorMessage={setErrorMessage} />
              </StripeWrapper>
            </>
          )}
          {globalError && (
            <>
              <GenericErrorAlert key="error" />
            </>
          )}
        </ErrorBoundary>
      )}
    </>
  );
};

const SubscriptionBlock = ({ setErrorMessage }: any) => {
  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/checkout/confirm`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.error(error.type);
      console.error(error.code);
      toast.error(`${error.message}`);
      setErrorMessage(error.message);
      // There was a problem trying to connect with your bank. Please try again in a few minutes. If the problem persists, contact your bank or use a different payment method
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  // https://stripe.com/docs/payments/payment-element?locale=en-GB
  const options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  return (
    <Form onSubmit={onSubmit}>
      <PaymentElement options={options} />
      <WrapperButtons>
        <PrimaryButton
          iconRight={<ChevronRightIcon color={Colors.White} size={1} />}
          // disabled={!stripe}
          type="submit"
          inline
          busy={false}
        >
          Confirm and Pay
        </PrimaryButton>
      </WrapperButtons>
    </Form>
  );
};

const StripeWrapper = ({ children, clientSecret }: WrapperProps) => {
  if (!stripePromise) return null;

  const appearance = {
    theme: 'stripe' as 'flat' | 'stripe',
    variables: {
      colorPrimary: '#A7BDA7',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
  };

  const options: StripeElementsOptions = {
    clientSecret,
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
    locale: 'en',
    appearance,
  };

  return (
    <StripeWrapperContent>
      <Elements options={options} stripe={stripePromise}>
        {children}
      </Elements>
    </StripeWrapperContent>
  );
};

const StripeWrapperContent = styled.div`
  padding: 64px 0;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const AnchorWrapper = styled.div`
  display: flex;
`;

type WrapperProps = {
  children: ReactNode;
  clientSecret: string;
};

export default Checkout;
