import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryProductsArgs } from '../../generated/types';

export const GET_PRODUCTS = gql`
  query getProducts($where: ProductsInputWhere!) {
    products(where: $where) {
      priceId
      active
      currency
      productName
      recurrence
      trialPeriodDays
      unitAmount
      unitAmountDecimal
    }
  }
`;

export const useGetProducts = (options?: QueryHookOptions<Pick<Query, 'products'>, QueryProductsArgs>) =>
  useQuery<Pick<Query, 'products'>, QueryProductsArgs>(GET_PRODUCTS, options);

export const useLazyGetProducts = (options?: QueryHookOptions<Pick<Query, 'products'>, QueryProductsArgs>) =>
  useLazyQuery<Pick<Query, 'products'>, QueryProductsArgs>(GET_PRODUCTS, options);
