import styled from 'styled-components';

export function converToPrice(number: any) {
  // is module 100
  const isModulo = number % 100 === 0;
  const value = isNaN(number) ? number : isModulo ? `£${number / 100}.00` : `£${number / 100}`;
  return <Span>{value}</Span>;
}

const Span = styled.span`
  // no breaking workd
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
